export default {
  data: function data() {
    return {
      current: 0,
      list: [{
        date: "2010 - 2011",
        img: require("@/assets/home/e/1.png")
      }, {
        date: "2012 - 2013",
        img: require("@/assets/home/e/2.png")
      }, {
        date: "2014 - 2015",
        img: require("@/assets/home/e/3.png")
      }, {
        date: "2016 - 2017",
        img: require("@/assets/home/e/4.png")
      }, {
        date: "2018 - 2019",
        img: require("@/assets/home/e/5.png")
      }, {
        date: "2020-2021",
        img: require("@/assets/home/e/6.png")
      }]
    };
  },
  mounted: function mounted() {
    var _this = this;

    $(this.$refs.positionBefore).on("animationend", function () {
      _this.current = ++_this.current % _this.list.length;
    });
  },
  unmounted: function unmounted() {
    $(this.$refs.positionBefore).unbind();
  }
};
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3042f581"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "instrument-f"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "list"
};
var _hoisted_4 = {
  class: "item-icon"
};
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  class: "item-t"
};
var _hoisted_7 = {
  class: "item-c"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.title), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: "item",
      key: index
    }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
      src: item.icon
    }, null, 8, _hoisted_5)]), _createElementVNode("div", _hoisted_6, _toDisplayString(item.t), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(item.c), 1)]);
  }), 128))])]);
}
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5329e60c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "gd",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.gd && $options.gd.apply($options, arguments);
    })
  }, [_createElementVNode("span", {
    style: _normalizeStyle({
      color: $props.color
    })
  }, "向下滚动", 4), _createElementVNode("img", {
    src: $props.icon
  }, null, 8, _hoisted_1)]);
}
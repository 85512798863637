import { createStore } from "vuex";

export default createStore({
  state: {
    productName: "",
    navs: [
      {
        name: "首页",
        to: "/home",
      },
      {
        name: "软件及解决方案",
        childWidth: "2rem",
        to: "/fixed-assets",
        child: [
          {
            name: "资产管理解决方案",
            to: "/fixed-assets",
          },
          {
            name: "智能包装解决方案",
            to: "/pack",
          },
          {
            name: "鞋服行业解决方案",
            to: "/smart-clothing",
          },
          {
            name: "防伪溯源解决方案",
            to: "/traceability",
          },
          // {
          //   name: "智能洗涤管理方案",
          //   to: "/wash",
          // },
          {
            name: "工器具智能管理方案",
            to: "/instrument",
          },
          {
            name: "智能仓储管理方案",
            to: "/warehousing",
          },
          {
            name: "消防器材管理方案",
            to: "/fireproof",
          },
        ],
      },
      {
        name: "产品中心",
        childWidth: "1.64rem",
        to: "/product?name=NFC标签&c=0",
        child: [
          {
            name: "RFID标签",
            to: "/product?name=NFC标签&c=0",
          },
          {
            name: "RFID读写器",
            to: "/product?name=一体化读写器&c=0",
          },
          {
            name: "RFID打印机",
            to: "/product?name=RFID打印机&c=0",
          },
          {
            name: "RFID硬件产品",
            to: "/product?name=智能收银机&c=0",
          },
        ],
      },
      {
        name: "应用案例",
        childWidth: "2rem",
        to: "/assets-manage",
        child: [
          {
            name: "RFID资产管理案例",
            to: "/assets-manage",
          },
          // {
          //   name: "RFID智能仓储",
          //   to: "/warehouse-logistic",
          // },
          {
            name: "RFID服装管理案例",
            to: "/clothing",
          },
          {
            name: "RFID防伪溯源系统",
            to: "/hotel",
            children: [
              {
                name: "酒品防伪溯源",
                to: "/cosmetic",
                small: true,
                style: { paddingLeft: ".4rem" },
              },
              {
                name: "化妆品防伪溯源",
                to: "/liquor",
                small: true,
                style: { paddingLeft: ".4rem" },
              },
            ],
          },
        ],
      },
      {
        name: "技术与服务",
        childWidth: "1.54rem",
        to: "/serve?type=before",
        child: [
          {
            name: "售前服务",
            to: "/serve?type=before",
          },
          {
            name: "实施服务",
            to: "/serve?type=ing",
          },
          {
            name: "售后服务",
            to: "/serve?type=after",
          },
        ],
      },
      {
        name: "新闻中心",
        to: "/tidings",
        footerHidden: true,
      },
      {
        name: "关于我们",
        childWidth: "1.54rem",
        to: "/about",
        child: [
          {
            name: "联系我们",
            to: "/connect",
          },
          {
            name: "关于公司",
            to: "/about",
          },
        ],
      },
    ],
    headerColor: "black",
    headerTopBtnWhite: false,
    isNews: false,
  },
  mutations: {
    setHeaderColorBlack(state) {
      state.headerColor = "black";
    },
    setHeaderColorWhite(state) {
      state.headerColor = "white";
    },
    setheaderTopBtnWhite(state, d) {
      state.headerTopBtnWhite = d;
    },
    setIsNews(state, d) {
      state.isNews = d;
    },
    setProductName(state, name) {
      state.productName = name;
    },
  },
  actions: {},
  modules: {},
  getters: {
    navs: (state) => state.navs,
    headerColor: (state) => state.headerColor,
    productName: (state) => state.productName,
    headerTopBtnWhite: (state) => state.headerTopBtnWhite,
    isNews: (state) => state.isNews,
  },
});

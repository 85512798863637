export default {
  data: function data() {
    return {
      current: 0,
      list: [{
        img: require("@/assets/home/a/1.jpg"),
        t: " 智能包装",
        to: "/pack"
      }, {
        img: require("@/assets/home/a/2.jpg"),
        t: "固定资产",
        to: "/fixed-assets"
      }, {
        img: require("@/assets/home/a/3.jpg"),
        t: "鞋服行业",
        to: "/smart-clothing"
      }]
    };
  }
};
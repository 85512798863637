import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/style/font.css";
import "@/style/hover.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import common from "@/components/common.js";
import MetaInfo from "vue-meta-info";
const app = createApp(App);

app.use(MetaInfo);
// let a = require.context("@/style");
// console.log(a, typeof a);
app.use(ElementPlus);
common(app);
app.use(store).use(router).mount("#app");

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-b6a30e7c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  class: "btns"
};
var _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "banner-container",
    ref: "con",
    style: _normalizeStyle($props.containerStyle)
  }, [$props.title ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "banner-title wow animate__fadeInDown",
    innerHTML: $props.title
  }, null, 8, _hoisted_1)) : _createCommentVNode("", true), _createElementVNode("div", {
    class: "banner",
    ref: "banner",
    style: _normalizeStyle($options.bannerStyle)
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["item", {
        current: index === $data.current
      }]),
      key: index,
      onClick: function onClick($event) {
        return $data.current = index;
      }
    }, [_createElementVNode("div", null, _toDisplayString(10 > index ? "0" + (index + 1) : index + 1) + ".", 1), _createElementVNode("div", null, _toDisplayString(item.t), 1), _createElementVNode("div", null, _toDisplayString(item.c), 1)], 10, _hoisted_2);
  }), 128))], 4), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["btn", {
        current: $data.current === index
      }]),
      key: index,
      onClick: function onClick($event) {
        return $data.current = index;
      },
      style: _normalizeStyle($data.current === index ? "background-image: url(".concat(item.icon2, ")") : "background-image: url(".concat(item.icon, ")"))
    }, null, 14, _hoisted_4);
  }), 128))])], 4);
}
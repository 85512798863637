import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "@/views/home/components/top";
import homeA from "@/views/home/components/home-a";
import homeB from "@/views/home/components/home-b";
import homeC from "@/views/home/components/home-c";
import homeD from "@/views/home/components/home-d";
import homeE from "@/views/home/components/home-e";
import homeF from "@/views/home/components/home-f";
import homeG from "@/views/home/components/home-g";
import commonFooter1 from "@/components/common-footer1";
import { mapMutations } from "vuex";
import TipDialog from "@/views/home/components/tipDialog.vue"; // import TDK from "/public/data/TDK.js";

export default {
  name: "Home",
  // metaInfo: {
  //   title: "RFID软件_RFID固定资产_RFID系统-深圳常达智能物联",
  //   meta: [
  //     {
  //       // set meta
  //       name: "keyWords",
  //       content:
  //         "RFID系统,RFID资产管理,RFID资产盘点,RFID软件集成,防伪溯源,常达,工厂rfid管理,rfid软件,rfid厂家,RFID固定资产,RFID智能工具柜,RFID工具箱",
  //     },
  //     {
  //       name: "description",
  //       content:
  //         "常达智能是RFID方案商，提供RFID软件系统开发，RFID固定资产管理方案，RFID智能包装方案，RFID仓储管理方案，RFID智能服装门店方案，RFID防伪溯源方案，RFID智能洗涤方案等RFID物联网解决方案。",
  //     },
  //   ],
  // },
  data: function data() {
    return {
      showMobile: false
    };
  },
  components: {
    TipDialog: TipDialog,
    commonFooter1: commonFooter1,
    top: top,
    homeA: homeA,
    homeB: homeB,
    homeC: homeC,
    homeD: homeD,
    homeE: homeE,
    homeF: homeF,
    homeG: homeG
  },
  watch: {// $route() {
    //   this.$router.go(0);
    // },
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  mounted: function mounted() {
    // TDK.setTDK("home");
    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};
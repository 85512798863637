export default {
  props: {
    visible: Boolean,
    list: Array
  },
  data: function data() {
    return {};
  },
  watch: {
    // visible: {
    //   immediate: true,
    //   handler() {
    // if (bool) $("body").css({ overflow: "hidden" });
    // else $("body").css({ overflow: "none" });
    // },
    // },
    $route: function $route() {
      this.$emit("update:visible", false);
    }
  }
};
import commonFooter from "./common-footer";
import commonFooter1 from "./common-footer1";
import commonHeader from "./common-header";
import commonColumnSwiper from "./common-column-swiper";
import commonSwiper from "./common-swiper";
import commonSwiper1 from "./common-swiper1";
import commonSwiper2 from "./common-swiper2";
import commonRoundSwiper from "./common-round-swiper";
import commonRoundSwiper1 from "./common-round-swiper1";
import commonRecommend from "./common-recommend";
import commonGd from "./common-gd";
import commonBackTop from "./common-back-top";
import commonList from "./common-list";
import commonList2 from "./common-list2";
import commonList3 from "./common-list3";
export default function (app) {
  app
    .component("common-footer", commonFooter)
    .component("common-footer1", commonFooter1)
    .component("common-header", commonHeader)
    .component("common-round-swiper", commonRoundSwiper)
    .component("common-round-swiper1", commonRoundSwiper1)
    .component("common-column-swiper", commonColumnSwiper)
    .component("common-swiper", commonSwiper)
    .component("common-swiper1", commonSwiper1)
    .component("common-swiper2", commonSwiper2)
    .component("common-recommend", commonRecommend)
    .component("common-gd", commonGd)
    .component("common-list", commonList)
    .component("common-list2", commonList2)
    .component("common-list3", commonList3)
    .component("common-back-top", commonBackTop);
}

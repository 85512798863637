export default {
  data: function data() {
    return {
      second: 15,
      show: false
    };
  },
  mounted: function mounted() {
    var _this = this;

    if (!sessionStorage.getItem('firstVisit')) {
      // 用户首次访问项目
      sessionStorage.setItem('firstVisit', 'no');
      setTimeout(function () {
        _this.show = true;
        var intervalId = setInterval(function () {
          if (_this.second > 1) {
            _this.second -= 1; // 每秒倒计时减1
          } else {
            clearInterval(intervalId); // 清除定时器

            _this.show = false;
          }
        }, 1000);
      }, 2000);
    } else {
      this.show = false;
    }
  }
};
export default {
  data: function data() {
    return {
      current: 0 // title: "方案",
      // list: [
      //   {
      //     t: "131231",
      //     c: "123123131321",
      //     icon: 555,
      //   },
      //   {
      //     t: "131231",
      //     c: "123123131321",
      //     icon: 555,
      //   },
      //   {
      //     t: "131231",
      //     c: "123123131321",
      //     icon: 555,
      //   },
      //   {
      //     t: "131231",
      //     c: "123123131321",
      //     icon: 555,
      //   },
      // ],

    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    containerStyle: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    bannerStyle: function bannerStyle() {
      var res = {};
      var current = this.current,
          list = this.list;
      var _this$$refs = this.$refs,
          banner = _this$$refs.banner,
          con = _this$$refs.con;
      res.width = 4.7 * list.length + "rem";
      var x = 450 * current;

      if (this.$refs.banner && this.$refs.con) {
        var bannerWidth = banner.offsetWidth;
        var conWidth = con.offsetWidth;

        if (bannerWidth - x < conWidth + 400 && current + 3 > list.length) {
          x = bannerWidth - conWidth + 400;
        }

        res.transform = "translateX(".concat(-x / 100, "rem)");
      }

      return res;
    }
  }
}; // document.getElementById("aaaa").onload = function () {
//   console.log(
//     document.getElementById("aaaa").contentWindow.document.documentElement.scrollHeight
//   );
// };
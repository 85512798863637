import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7783ac7c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["reToTop", {
      hide: !$data.reTopShow
    }]),
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.toTop && $options.toTop.apply($options, arguments);
    })
  }, [_createElementVNode("img", {
    src: require('@/assets/retop.png')
  }, null, 8, _hoisted_1)], 2);
}
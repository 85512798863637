export default {
  data: function data() {
    return {
      current: 0
    };
  },
  props: {
    list: Array,
    title: String,
    info: String,
    reverse: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    listStyle: function listStyle() {
      if (this.reverse) return "transform: translateX(".concat(-3.32 * this.current, "rem);");else return "transform: translateX(".concat(3.32 * this.current, "rem);");
    }
  },
  methods: {
    doubleDigits: function doubleDigits(value) {
      value = parseInt(value);
      return value >= 10 ? value : "0" + value;
    }
  }
};
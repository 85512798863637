import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.slice.js";
import { mapGetters } from "vuex";
export default {
  computed: _objectSpread(_objectSpread({}, mapGetters({
    list: "navs"
  })), {}, {
    unHomeList: function unHomeList() {
      return this.list.slice(1);
    }
  })
};
import { createRouter, createWebHistory } from "vue-router"; //createWebHistory createWebHashHistory
import Home from "@/views/home";
// import FixedAssets from "@/views/solution/fixed-assets/index";
// import SmartClothing from "@/views/solution/smart-clothing/index";
// import Pack from "@/views/solution/pack/index";
// import Traceability from "@/views/solution/traceability/index";
// import Instrument from "@/views/solution/instrument/index";
// import Wash from "@/views/solution/wash/index";
// import Warehousing from "@/views/solution/warehousing/index";
// import Fireproof from "@/views/solution/fireproof/index";
// import Product from "@/views/product/index";
// import AssetsManage from "@/views/case/assets-manage/index";
// import WarehouseLogistic from "@/views/case/warehouse-logistics/index";
// import Hotel from "@/views/case/hotel/index";
// import About from "@/views/about/index/index";
// import Connect from "@/views/about/connect/index";
// import Serve from "@/views/serve/index";
// import Cosmetic from "@/views/case/cosmetic/index";
// import Liquor from "@/views/case/liquor/index";

// import After from "@/views/serve/after/index";
// import Before from "@/views/serve/before/index";
// import Ing from "@/views/serve/ing/index";
import Container from "@/components/container";
const routes = [
  {
    path: "/",
    redirect: "/home",
    component: Container,
    children: [
      {
        path: "/home",
        component: Home,
        name: "Home",
        meta: {
          title: "RFID软件_RFID资产管理_RFID系统-深圳常达智能物联",
          keywords: "RFID系统,RFID资产管理,RFID资产盘点,RFID软件,PDA,防伪溯源,常达,工厂rfid管理,rfid软件,rfid厂家,RFID固定资产,RFID智能工具柜,RFID工具箱",
          description:
            "常达智能是RFID方案商，提供RFID软件系统开发，RFID资产管理方案，RFID智能包装方案，RFID仓储管理方案，RFID鞋服行业管理方案，RFID防伪溯源方案，RFID智能洗涤方案等RFID物联网解决方案。",
        },
      },
      {
        path: "fixed-assets",
        // component: FixedAssets,
        component: () => import("@/views/solution/fixed-assets/index"),
        name: "FixedAssets",
        meta: {
          title: "资产管理解决方案-资产管理系统",
          keywords:
            "RFID资产管理系统,RFID资产管理方案,物联网资产管方案,RFID电子标签资产管理方案,常达,rfid物资管理系统,rfid资产盘点,rfid管理系统,rfid管理软件,rfid资产盘点系统,rfid资产盘点,rfid资产管理,rfid资产管理系统,RFID盘点,资产管理系统,rfid资产盘点系统,医院rfid资产管理",
          description:
            "企业的资产种类繁多， 很多时候因资源太锁碎多变往往忽略其成本控制和财务统计的重要性。 常达智能可通过超高频RFID设备， 结合前端人工数据收集和后台计算大数据库， 促使企业轻松记录， 实时监察资源和记录， 充分体现“ 帐、 卡、 物” 数据相符的资产管理系统。",
        },
      },
      {
        path: "smart-clothing",
        // component: SmartClothing,
        component: () => import("@/views/solution/smart-clothing/index"),
        name: "SmartClothing",
        meta: {
          title: "鞋服行业管理解决方案",
          keywords:
            "RFID智能服装门店,服装RFID盘点,RFID鞋服行业管理,RFID服装管理系统,常达,服装erp软件,服装零售erp,快时尚ERP,智慧门店,服装软件,服装RFID收银系统,服装RFID管理系统,服装erp管理系统,服装rfid,服装门店管理系统,服装erp系统,rfid吊牌,PDA,rfid服装吊牌,rfid服装吊牌厂家,资产管理系统",
          description:
            "常达智能物联运用RFID技术引入商品管理，结合无线网络通讯技术的应用，实现销售现场商品信息的实时查询，以及销售数据的上传、智能化统计与管理，方便快捷，提升工作效率，降低运营成本的同时，显著提高商场档次，提升消费者购物体验。",
        },
      },
      {
        path: "pack",
        // component: Pack,
        component: () => import("@/views/solution/pack/index"),
        name: "Pack",
        meta: {
          title: "智能包装解决方案",
          keywords: "RFID智能包装,防伪溯源,防伪溯源整体方案",
          description:
            "智能包装解决方案是整合包装、智能手机应用、NFC & RFID 芯片技术和数据来连接企业和客户的需求，提供智能包装的一站式的解决方案。智能包装套装 3.0版本包括智能包装、webAPP、Dashboard、PDA。",
        },
      },
      {
        path: "traceability",
        // component: Traceability,
        component: () => import("@/views/solution/traceability/index"),
        name: "Traceability",
        meta: {
          title: "防伪溯源解决方案",
          keywords:
            "防伪溯源,防伪溯源整体方案,RFID智能包装,RFID标签,防伪标签,防窜货系统",
          description:
            "通过二维码、RFID电子标签等技术，对单个产品赋予身份编码及认证信息，利用先进的数据采集技术，物联网技术及大数据处理技术，实现产品在生产环节、仓库环节、物流环节、渠道销售及消费者互动等环节的追溯管理，确保产品整个生命周期过程能被追踪得到，全面提升了企业的市场反应速度和管理水平。",
        },
      },
      {
        path: "instrument",
        // component: Instrument,
        component: () => import("@/views/solution/instrument/index"),
        name: "Instrument",
        meta: {
          title: "工器具智能管理方案",
          keywords:
            "RFID仓储管理方案,RFID手持设备,RFID智能工具柜,智能工具箱,RFID智能工具管理系统方案",
          description:
            "利用RFID技术，把贴有RFID标签的工具放入特殊定制并加装RFID读写器的工具车、工具柜或工具箱中，实现工具借用、归还、查找、定位、盘点。",
        },
      },
      {
        path: "wash",
        // component: Wash,
        component: () => import("@/views/solution/wash/index"),
        name: "Wash",
        meta: {
          title: "智能洗涤管理方案",
          keywords:
            "RFID洗涤管理方案 ,洗衣布草,RFID标签 超高频手持机 洗衣管理系统",
          description:
            "随着RFID技术日益升级，RFID标签被广泛应用于各类洗衣场景中，并使传统手动记录洗衣过程转变成了全自动处理与记录洗衣过程。而且在洗涤产品上缝制RFID标签，可使用户利用RFID标签的全球唯一码自动识别和跟踪洗衣过程，并获取数据，方便用户后期指定更高质量的决策。",
        },
      },
      {
        path: "warehousing",
        // component: Warehousing,
        component: () => import("@/views/solution/warehousing/index"),
        name: "Warehousing",
        meta: {
          title: "智能仓储管理方案",
          keywords:
            "RFID仓储物流管理系统,RFID仓库管理系统,RFID仓储管理系统,RFID智能仓储管理系统",
          description:
            "自动管理仓库系统(WMS)已经大规模地应用于各个行业，特别是在制造业及物流业。一套好的WMS 除了可以整合各种设备系统之外，也可以提供数据连接到整个企业的讯息化系统里，为企业提供更多样的讯息。",
        },
      },
      {
        path: "fireproof",
        // component: Fireproof,
        component: () => import("@/views/solution/fireproof/index"),
        name: "Fireproof",
        meta: {
          title: "消防器材管理方案",
          keywords:
            "RFID标签,消防器材管理方案,RFID仓储管理方案,消防设备管理的RFID解决方案,RFID手持设备",
          description:
            "运用 RFID 技术的消防器材固定资产管理系统，通过在消防器材上安装 RFID 电子标签，在后台绑定对应的资产信息，在关键节点和流程，通过对 RFID 电子标签信息的采集，来精确获取到资产的详细信息，从而实现管理器材的快速盘点以及出入库管理， 提高效率的同时避免盘点漏缺情况的发生。",
        },
      },
      {
        path: "product",
        // component: Product,
        component: () => import("@/views/product/index"),
        name: "Product",
        meta: {
          title: "",
          keywords: "",
          description: "",
        },
      },
      {
        path: "assets-manage",
        // component: AssetsManage,
        component: () => import("@/views/case/assets-manage/index"),
        name: "AssetsManage",
        meta: {
          title: "RFID资产管理案例",
          keywords:
            "RFID固定资产管理系统,RFID固定资产管理方案,物联网资产管方案,RFID电子标签资产管理方案",
          description:
            "企业的固定资产种类繁多， 很多时候因资源太锁碎多变往往忽略其成本控制和财务统计的重要性。 常达智能可通过超高频RFID设备， 结合前端人工数据收集和后台计算大数据库， 促使企业轻松记录， 实时监察资源和记录， 充分体现“ 帐、 卡、 物” 数据相符的资产管理系统。",
        },
      },
      {
        path: "clothing",
        // component: WarehouseLogistic,
        component: () => import("@/views/case/clothing/index"),
        name: "clothing",
        meta: {
          title: "",
          keywords: "",
          description: "",
        },
      },
      {
        path: "warehouse-logistic",
        // component: WarehouseLogistic,
        component: () => import("@/views/case/warehouse-logistics/index"),
        name: "WarehouseLogistic",
        meta: {
          title: "RFID智能仓储管理系统",
          keywords: "RFID仓储,仓储管理,RFID智能仓储,RFID技术",
          description:
            "RFID仓储管理系统是基于RFID识别技术为货物识别追踪、管理和查验货物信息的平台。将先进的RFID识别技术和计算机的数据库管理查询相结合，自动识别货物信息，实现企业物流运作的自动化、信息化、智能化的需求。",
        },
      },
      {
        path: "liquor",
        // component: Liquor,
        component: () => import("@/views/case/liquor/index"),
        name: "Liquor",
        meta: {
          title: "化妆品防伪溯源",
          keywords:
            "固定资产,仓储管理,防伪溯源,RFID标签,手持机,固定资产管理方案,RFID手持设备,RFID仓储管理方案,防伪溯源整体方案",
          description: "化妆品防伪溯源",
        },
      },
      {
        path: "cosmetic",
        // component: Cosmetic,
        component: () => import("@/views/case/cosmetic/index"),
        name: "Cosmetic",
        meta: {
          title: "酒品防伪溯源",
          keywords:
            "固定资产,仓储管理,防伪溯源,RFID标签,手持机,固定资产管理方案,RFID手持设备,RFID仓储管理方案,防伪溯源整体方案",
          description:
            "面对世界经济一体化的发展态势，某某集团不断加强与全球业务伙伴在农产品、 粮油食品、番茄果蔬、饮料、酒业、糖业、饲料、肉食以及生物质能源、地产酒 店、 金融等领域的广泛合作。 ",
        },
      },
      {
        path: "hotel",
        // component: Hotel,
        component: () => import("@/views/case/hotel/index"),
        name: "Hotel",
        meta: {
          title: "防伪溯源系统",
          keywords: "防伪溯源系统",
          description: "防伪溯源系统",
        },
      },
      {
        path: "tidings",
        // component: About,
        component: () => import("@/views/news/index"),
        name: "News",
        meta: {
          title: "RFID技术系统解决方案供应商_深圳常达智能",
          keywords: "新闻中心",
          description:
            "常达智能物联（深圳）有限公司为全资港资企业，专注于RFID核心技术、拥有自主研发团队，开发RFID系统平台/RFID产品与RFID项目解决方案，为客户提供一站式RFID解决方案和配套软硬件的开发与实施服务。",
        },
      },
      {
        path: "about",
        // component: About,
        component: () => import("@/views/about/index/index"),
        name: "About",
        meta: {
          title: "RFID技术系统解决方案供应商_深圳常达智能",
          keywords: "关于公司",
          description:
            "常达智能物联（深圳）有限公司为全资港资企业，专注于RFID核心技术、拥有自主研发团队，开发RFID系统平台/RFID产品与RFID项目解决方案，为客户提供一站式RFID解决方案和配套软硬件的开发与实施服务。",
        },
      },
      {
        path: "connect",
        // component: Connect,
        component: () => import("@/views/about/connect/index"),
        name: "Connect",
        meta: {
          title: "联系我们_常达智能",
          keywords: "联系我们",
          description:
            "常达智能物联公司地址：深圳市龙岗区雅宝路1号星河WORLD A栋大厦13层 A1303B",
        },
      },
      {
        path: "serve",
        // component: Serve,
        component: () => import("@/views/serve/index"),
        name: "Serve",
        meta: {
          title: "技术与服务_常达智能",
          keywords: "售前服务,实施服务,售后服务",
          description:
            "我们始终凭借领先的产品、专业成熟的解决方案、专业的实施和客户优先的服务在行业享有很高知名度。公司致力于为客户提供专业的RFID技术方案咨询、物联网规划与管理咨询服务，帮助客户建立IT支撑的最佳管理体系，全面提升企业的核心竞争能力。我们多年专注于RFID技术方案在服装行业的研究、市场推广和实施服务，可以免费提供远程技术支援，提供技术咨询应答服务（节假日除外）；免费提供产品选型、应用方案、产品使用和维护等方面的咨询；免费为客户相关人员提供及时的当地技术培训。热线支持：服务人员通过电话向用户提供技术问题解答的过程。远程维护：安排技术人员为用户提供网络远程解决问题的过程。产品升级：定期官方发布的软件版本,优化体验。功能改进：根据客户需求对软件功能进行收集与分析进行统一产品完善。",
        },
      },
      {
        path: "child",
        component: () => import("@/views/newsChilds/index.vue"),
        name: "child",
        meta: {
          title: "RFID技术系统解决方案供应商_深圳常达智能",
          keywords: "新闻",
          description:
            "常达智能物联（深圳）有限公司为全资港资企业，专注于RFID核心技术、拥有自主研发团队，开发RFID系统平台/RFID产品与RFID项目解决方案，为客户提供一站式RFID解决方案和配套软硬件的开发与实施服务。",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory,
  routes,
});
// function setMeta(name, value) {
//   let dom = document.querySelector(`meta[name="${name}"]`);
//   dom && dom.setAttribute("content", value);
// }
// router.beforeEach((to) => {
//   let { title, keywords, description } = to.meta;
//   if (title) document.title = title;
//   if (keywords) {
//     setMeta("keywords", keywords);
//   }
//   if (description) {
//     setMeta("description", description);
//   }
//   if (title) {
//     setMeta("title", title);
//   }
// });

// function getRoutesList(routes, pre) {
//   return routes.reduce((array, route) => {
//     const path = `${pre}${route.path}`;
//
//     if (route.path !== "*") {
//       array.push(path);
//     }
//
//     if (route.children) {
//       array.push(...getRoutesList(route.children, `${path}`));
//     }
//
//     return array;
//   }, []);
// }
//
// function getRoutesXML() {
//   const list = getRoutesList(
//     router.options.routes,
//     "https://www.cirteklink.com"
//   )
//     .map((route) => {
//       return `<url>
//                 <loc>${route}</loc>
//                 <changefreq>daily</changefreq>
//                 <priority>0.8</priority>
//               </url>`;
//     })
//     .join("\r\n");
//   return `
//       <urlset
//         xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
//         xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
//         xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd"
//        >
//         ${list}
//       </urlset>`;
// }

// console.log(getRoutesXML());
export default router;

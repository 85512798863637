export default {
  data: function data() {
    return {
      list: [{
        img: require("@/assets/home/f/01.jpg")
      }, {
        img: require("@/assets/home/f/02.jpg")
      }, {
        img: require("@/assets/home/f/03.png")
      }, {
        img: require("@/assets/home/f/04.jpg")
      }, {
        img: require("@/assets/home/f/05.jpg")
      }, {
        img: require("@/assets/home/f/06.jpg")
      }, // { img: require("@/assets/home/f/07.jpg") },
      {
        img: require("@/assets/home/f/08.png")
      }, {
        img: require("@/assets/home/f/10.jpg")
      }],
      current: 0,
      time: null
    };
  },
  methods: {// addAnimate() {
    //   let { current, addAnimate, list } = this;
    //   let animation = this.$refs.list.animate(
    //     [
    //       { transform: `translateX(${-450 * current}px)` },
    //       { transform: `translateX(${-450 * (current + 1)}px)` },
    //     ],
    //     {
    //       duration: 3000,
    //       "fill-mode": "forwards",
    //       "timing-function": "linear",
    //       delay: "1000",
    //     }
    //   );
    //   // this.current++;
    //   // if(this.current === list.length)this.current=0
    //   this.current = ++this.current % list.length;
    //   animation.addEventListener(
    //     "finish",
    //     function () {
    //       addAnimate();
    //     },
    //     false
    //   );
    // },
  },
  mounted: function mounted() {// this.addAnimate();
    // let { list } = this;
    // let _this = this;
    // this.time = setInterval(() => {
    //   if (this.current === list.length) {
    //     _this.current = 0;
    //     this.$refs.list.style.transition = "none";
    //     this.$refs.list.style.transform = `translateX(0px)`;
    //   } else {
    //     this.current++;
    //     this.$refs.list.style.transition = "all 3s linear";
    //     this.$refs.list.style.transform = `translateX(${
    //       -355 * this.current
    //     }px)`;
    //   }
    // }, 3000);
  },
  unmounted: function unmounted() {
    this.time && clearInterval(this.time);
  }
};
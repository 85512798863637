import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5284cae2"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "main"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = ["onClick"];
var _hoisted_4 = ["onClick"];
var _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "mobile-nav",
    style: _normalizeStyle(!$props.visible && 'transform:translateX(100%);')
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: "close",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('update:visible', false);
    })
  }, [_createElementVNode("img", {
    src: require('@/assets/close.png')
  }, null, 8, _hoisted_2)]), _createElementVNode("div", {
    class: "list",
    onTouchend: _cache[1] || (_cache[1] = _withModifiers(function () {}, ["stop"])),
    onTouchstart: _cache[2] || (_cache[2] = _withModifiers(function () {}, ["stop"])),
    onTouchmove: _cache[3] || (_cache[3] = _withModifiers(function () {}, ["stop"]))
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: "list-item",
      key: index
    }, [_createElementVNode("div", {
      class: _normalizeClass([item.to && 'to', "nav"]),
      onClick: function onClick($event) {
        return item.to && _ctx.$router.push(item.to);
      }
    }, _toDisplayString(item.name), 11, _hoisted_3), item.child ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList(item.child, function (childItem, childIndex) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([childItem.to && 'to', "child-nav"]),
        key: childIndex
      }, [_createElementVNode("div", {
        onClick: function onClick($event) {
          return childItem.to && _ctx.$router.push(childItem.to);
        }
      }, _toDisplayString(childItem.name), 9, _hoisted_4), childItem.children ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList(childItem.children, function (child2Item, child2Index) {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass([child2Item.to && 'to', "child2-nav child-nav"]),
          onClick: function onClick($event) {
            return child2Item.to && _ctx.$router.push(child2Item.to);
          },
          key: child2Index
        }, [_createElementVNode("div", null, _toDisplayString(child2Item.name), 1)], 10, _hoisted_5);
      }), 128)) : _createCommentVNode("", true)], 2);
    }), 128)) : _createCommentVNode("", true)]);
  }), 128))], 32)])], 4);
}
export default {
  props: {
    option: {
      type: Object,
      default: function _default() {}
    },
    lTitleArray: Boolean
  },
  data: function data() {
    return {
      current: 0
    };
  },
  methods: {
    resetPosition: function resetPosition() {
      var _this$option = this.option,
          list = _this$option.list,
          _this$option$defaultT = _this$option.defaultTop,
          defaultTop = _this$option$defaultT === void 0 ? 0 : _this$option$defaultT,
          _this$option$defaultB = _this$option.defaultBottom,
          defaultBottom = _this$option$defaultB === void 0 ? 0 : _this$option$defaultB; // let domLeft = this.$refs.left;

      var domContainer = this.$refs.container;
      var domBox = this.$refs.box;
      var domBoxHeight = parseInt($(domBox).css("height"));
      var domContainerTop = $(domContainer).offset().top;
      var domContainerHeight = parseInt($(domContainer).css("height"));
      var scrollTop = document.documentElement.scrollTop | document.body.scrollTop;
      var top = scrollTop - domContainerTop + 150;

      if (top < 0) {
        top = 0;
      } else if (top > domContainerHeight - domBoxHeight + defaultBottom) {
        top = domContainerHeight - domBoxHeight + defaultBottom;
      } // console.log(top);


      $(domBox).css({
        top: top + defaultTop + "px"
      });
      this.current = Math.floor(top / (domContainerHeight / list.length)); // console.log(top / (domContainerHeight / list.length));
    }
  },
  mounted: function mounted() {
    var _this = this;

    $(window).on("scroll resize", function () {
      _this.resetPosition();
    });
  },
  unmounted: function unmounted() {
    $(window).unbind();
  }
};
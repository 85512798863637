export default {
  data: function data() {
    return {
      reTopShow: false
    };
  },
  methods: {
    toTop: function toTop() {
      $("html,body").animate({
        scrollTop: 0
      }, 150);
    },
    reset: function reset() {
      var scrollTop = document.documentElement.scrollTop | document.body.scrollTop;

      if (scrollTop > 600) {
        this.reTopShow = true;
      } else {
        this.reTopShow = false;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.reset();
    $(window).on("scroll", function () {
      _this.reset();
    });
  }
};
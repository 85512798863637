import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
export default {
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    containerStyle: Object
  },
  data: function data() {
    return {
      imageWidth: "",
      current: null // list: [
      //   {
      //     image: require("@/assets/smart-clothing/b/1.png"),
      //     text: "RFID智慧门店</br>系统的应用场景",
      //     imgOffset: "-40%",
      //   },
      //   {
      //     image: require("@/assets/smart-clothing/b/2.png"),
      //     text: "智能魔镜",
      //     imgOffset: "-53%",
      //   },
      //   {
      //     image: require("@/assets/smart-clothing/b/3.png"),
      //     text: "智能货架",
      //     imgOffset: "-10%",
      //   },
      //   {
      //     image: require("@/assets/smart-clothing/b/4.png"),
      //     text: "智能试衣间",
      //     imgOffset: "-24%",
      //   },
      //   {
      //     image: require("@/assets/smart-clothing/b/5.png"),
      //     text: "货物盘点功能",
      //     imgOffset: "-60%",
      //   },
      //   {
      //     image: require("@/assets/smart-clothing/b/6.png"),
      //     text: "RFID智慧收银",
      //     imgOffset: "-80%",
      //   },
      //   {
      //     image: require("@/assets/smart-clothing/b/7.png"),
      //     text: "防盗防损系统",
      //     imgOffset: "-44%",
      //   },
      // ],

    };
  },
  computed: {
    itemWidth: function itemWidth() {
      return 100 / this.list.length;
    }
  },
  methods: {
    format: function format(str) {
      var reg = /<\/?.+?\/?>/g;
      return str.replace(reg, "");
    }
  },
  mounted: function mounted() {
    this.imageWidth = this.$refs.main.offsetWidth;
  }
};
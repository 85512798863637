import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-72eca8fa"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "left"
};
var _hoisted_2 = {
  class: "t wow animate__fadeInDown"
};
var _hoisted_3 = {
  key: 0,
  class: "c wow animate__fadeInDown"
};
var _hoisted_4 = {
  class: "list"
};
var _hoisted_5 = ["src"];
var _hoisted_6 = ["src", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex-rc container", {
      reverse: $props.reverse
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.title), 1), $props.info ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.info), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: "item",
      key: index
    }, [_createElementVNode("p", null, _toDisplayString(item.t), 1), _createElementVNode("p", null, _toDisplayString(item.c), 1), _createElementVNode("img", {
      src: item.icon
    }, null, 8, _hoisted_5)]);
  }), 128))])]), _createElementVNode("div", {
    class: _normalizeClass(["image wow", $props.reverse ? 'animate__fadeInLeft' : 'animate__fadeInRight'])
  }, [_createElementVNode("img", {
    src: $props.list[$data.current] && $props.list[$data.current].img,
    alt: $props.title
  }, null, 8, _hoisted_6)], 2)], 2);
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-91561d28"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "left",
  ref: "box"
};
var _hoisted_2 = {
  key: 0,
  class: "left-t"
};
var _hoisted_3 = {
  key: 1,
  class: "left-t"
};
var _hoisted_4 = {
  key: 2,
  class: "left-c"
};
var _hoisted_5 = {
  key: 3,
  class: "left-c"
};
var _hoisted_6 = {
  class: "right"
};
var _hoisted_7 = {
  key: 0,
  class: "item-t"
};
var _hoisted_8 = {
  key: 1,
  class: "item-c"
};
var _hoisted_9 = {
  key: 2,
  class: "item-bg"
};
var _hoisted_10 = ["src"];
var _hoisted_11 = {
  key: 3,
  class: "item-big-img"
};
var _hoisted_12 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "common-column-swiper-container",
    ref: "container",
    style: _normalizeStyle({
      'padding-bottom': $props.option.paddingBottom ? $props.option.paddingBottom + 'px' : 0
    })
  }, [_createElementVNode("div", _hoisted_1, [$props.lTitleArray ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString($props.option.list[$data.current].lt), 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.option.leftT, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, _toDisplayString(item), 1);
  }), 128))])), $props.lTitleArray ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.option.list[$data.current].lc), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($props.option.leftC), 1))], 512), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.option.list, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: "item",
      key: index
    }, [item.t ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(item.t), 1)) : _createCommentVNode("", true), item.c ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(item.c), 1)) : _createCommentVNode("", true), item.img && index === $data.current ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("img", {
      src: item.img
    }, null, 8, _hoisted_10)])) : _createCommentVNode("", true), item.bigImg ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("img", {
      src: item.bigImg
    }, null, 8, _hoisted_12)])) : _createCommentVNode("", true)]);
  }), 128))])], 4);
}
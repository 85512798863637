export default {
  data: function data() {
    return {
      enlarge: false,
      stop: false,
      current: 0,
      img: require("@/assets/qita/touming.png"),
      list: [{
        t: "RFID",
        img: require("@/assets/home/b/1.jpg")
      }, {
        t: "NFC",
        img: require("@/assets/home/b/2.jpg")
      }, {
        t: "AR",
        img: require("@/assets/home/b/3.gif")
      }, {
        t: "VR",
        // video: require("@/assets/home/b/4.mp4"),
        type: "video",
        img: require("@/assets/home/b/4.png")
      }]
    };
  },
  mounted: function mounted() {
    var _this = this;

    $(this.$refs.progress).on("animationend", function () {
      _this.current = ++_this.current % _this.list.length;
    }); // let eleVideo = document.getElementById("video");
    // eleVideo.addEventListener("play", function () {
    //   this.stop = true;
    // });
    // $("#video").on("play", function () {
    //   alert(1);
    // });
    // $("#video").on("pause", function () {
    //   this.stop = false;
    // });
  },
  unmounted: function unmounted() {
    $(this.$refs.progress).unbind();
  }
};
export default {
  data: function data() {
    return {
      current: 0
    };
  },
  beforeCreated: function beforeCreated() {},
  created: function created() {},
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    title: String,
    info: String,
    reverse: {
      default: false,
      type: Boolean
    }
  },
  computed: {// listStyle() {
    //   if (this.reverse)
    //     return `transform: translateX(${-3.32 * this.current}rem);`;
    //   else return `transform: translateX(${3.32 * this.current}rem);`;
    // },
  },
  methods: {
    doubleDigits: function doubleDigits(value) {
      value = parseInt(value);
      return value >= 10 ? value : "0" + value;
    }
  }
};
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue"; // import { Swiper as swiper } from "swiper";
// Import Swiper styles
// import "swiper/swiper.min.css"

import "swiper/swiper-bundle.min.css"; // SwiperCore.use([Pagination]);

export default {
  props: {
    title: String,
    list: Array,
    containerStyle: Object,
    flag: Boolean
  },
  components: {
    Swiper: Swiper,
    SwiperSlide: SwiperSlide
  },
  data: function data() {
    return {
      current: 0,
      up: "1",
      mySwiper: null
    };
  },
  computed: {
    // Pagination() {
    // return Pagination;
    // },
    bottomBtns: function bottomBtns() {
      return Math.ceil(this.list.length / 5);
    },
    x: function x() {
      var list = this.list,
          current = this.current,
          up = this.up;
      var len = list.length;
      if (len <= 5 || current === 0) return 0;
      var n = current * 100; // let f = (len % 5) * 20;

      var x;

      if ((current + 1) * 5 > len) {
        var f = (5 - (len - current * 5)) * 20;
        x = n - f;
      } else {
        x = n;
      }

      return up && x;
    },
    // x() {
    //   let {
    //     list,
    //     current,
    //     $refs: { main, list: listDom },
    //     up,
    //   } = this;
    //   let len = list.length;
    //   if (len <= 5 || current === 0) return 0;
    //
    //   let x = main.offsetWidth * current;
    //   let listDomWidth = listDom.offsetWidth;
    //   let mainWidth = main.offsetWidth;
    //   if (listDomWidth - x < mainWidth) {
    //     x = listDomWidth - mainWidth;
    //   }
    //   return up && x;
    // },
    itemWidth: function itemWidth() {
      var main = this.$refs.main,
          up = this.up; // console.log(main && main.offsetWidth);

      return main && up ? main.offsetWidth / 5 : 0;
    }
  },
  methods: {
    setSwiperInstance: function setSwiperInstance(s) {
      this.mySwiper = s;
    },
    to: function to(_to) {
      if (_to) this.$router.push(_to);
    },
    slideChange: function slideChange($event) {
      // console.log($event);
      this.current = Math.ceil($event.activeIndex / 5 % 5);
    },
    bottomBtn: function bottomBtn(index) {
      //   console.log(new swiper(this.$refs.main.$el));
      //   new swiper(this.$refs.main.$el).slideTo(index, 1000);
      // let dom = this.$refs.main.$el;
      // if (index === 0) {
      //   $(dom).addClass("my-swiper-transition");
      //   setTimeout(() => {
      //     $(dom).removeClass("my-swiper-transition");
      //   }, 1000);
      // }
      this.current = index;
      this.mySwiper && this.mySwiper.slideTo(index * 5); // new swiper(dom, {
      //   slidesPerView: 5,
      // }).slideTo(index * 5, 1000);
    }
  },
  mounted: function mounted() {
    this.up = new Date();
  }
};
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    theme: {
      type: Object,
      default: function _default() {
        return {
          color: "#5ec7b9",
          borderColor: "#5ec7b9"
        };
      }
    },
    containerStyle: {
      type: Object,
      default: function _default() {}
    },
    images: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    t: String,
    c: String
  },
  computed: {
    cItemStyle: function cItemStyle() {
      var res;

      if (this.reverse) {
        res = {
          transform: "translate(-50%,-50%) rotate(".concat(-(this.current - 1) * this.deg, "deg)")
        };
      } else {
        res = {
          transform: "translate(-50%,-50%) rotate(".concat((this.current - 1) * this.deg, "deg)")
        };
      }

      return res;
    },
    deg: function deg() {
      var len = this.images.length - 1;
      len = len < 3 ? 3 : len;
      var du = 240 / len;
      return du;
    },
    containerReverse: function containerReverse() {
      var res = {};
      var reverse = this.reverse;
      if (reverse) res.flexDirection = "row-reverse";else res.flexDirection = "row";
      return res;
    },
    leftStyle: function leftStyle() {
      var res = {};
      var reverse = this.reverse;
      if (reverse) res.paddingRight = "112px";else res.paddingLeft = "112px";
      return res;
    },
    cStyle: function cStyle() {
      var reverse = this.reverse,
          r = this.r,
          theme = this.theme,
          current = this.current,
          deg = this.deg;
      var res = {
        width: 2 * r + "px",
        height: 2 * r + "px"
      };
      if (theme.borderColor) res.borderColor = theme.color; // :style="theme.border && `border:${theme.color}`"

      if (reverse) {
        res.marginRight = -r / 3 + "px";
        res.transform = "translateX(-45%) rotate(".concat((current - 1) * deg, "deg)");
      } else {
        res.transform = "translateX(45%) rotate(".concat(-(current - 1) * deg, "deg)");
        res.marginLeft = -r / 3 + "px";
      }

      return res;
    },
    textStyle: function textStyle() {
      var reverse = this.reverse;
      var res = {
        oper: {},
        operBtn: {},
        t: {},
        title: {}
      };

      if (reverse) {
        res.oper = {}; // res.oper = { flexDirection: "row-reverse" };
        // res.operBtn = { marginLeft: "40px" };

        res.operBtn = {
          marginRight: "40px"
        };
        res.t = {}; // res.t = { flexDirection: "row-reverse" };

        res.title = {
          textAlign: "right"
        };
        res.titleBox = {
          marginRight: "40px"
        };
      } else {
        res.operBtn = {
          marginRight: "40px"
        };
        res.titleBox = {
          marginLeft: "40px"
        };
      }

      return res;
    }
  },
  data: function data() {
    return {
      current: 0,
      r: 445
    };
  },
  methods: {
    previous: function previous() {
      if (this.current > 0) {
        this.current--;
      }
    },
    next: function next() {
      if (this.current < this.images.length - 1) {
        this.current++;
      }
    },
    xy: function xy(i) {
      // let len = this.images.length - 1;
      // len = len < 3 ? 3 : len;
      var r = this.r,
          reverse = this.reverse,
          deg = this.deg;
      var du;
      if (reverse) du = deg * (i - 1) - 270;else du = 270 - deg * (i - 1);
      Math.sin(2 * Math.PI / 360 * du);
      Math.cos(2 * Math.PI / 360 * du);
      var res = {
        left: r + Math.sin(2 * Math.PI / 360 * du) * r + "px",
        top: r + Math.cos(2 * Math.PI / 360 * du) * r + "px"
      };

      if (i === 1) {
        res.width = 75 + r + "px";
        res.height = 75 + r + "px";
      }

      return res;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.images.forEach(function (item, index) {
      var d = _this.xy(index);

      $(_this.$refs["r" + index]).css({
        top: d.top,
        left: d.left
      });
    });
  }
};
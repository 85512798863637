export default {
  data: function data() {
    return {
      list: [{
        c: "无线射频识别，UHF，HF，NFC",
        t: "RFID标签",
        img: require("@/assets/home/c/1.png"),
        to: "/product?name=NFC标签&current=0"
      }, {
        c: "高速、准确的数据写入，放心的RFID自检功能",
        t: "RFID 打印机",
        img: require("@/assets/home/c/2.png"),
        to: "/product?name=RFID打印机&c=0"
      }, {
        c: "适用于各种环境的固定式RFID读取设备",
        t: "RFID固定式读写器",
        img: require("@/assets/home/c/3.png"),
        to: "/product?name=一体化读写器"
      }, {
        c: "轻巧便携 、性能强劲、 适用场景广",
        t: "RFID 手持机",
        img: require("@/assets/home/c/4.png"),
        to: "/product?name=手持机"
      }]
    };
  }
};
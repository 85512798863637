export default {
  methods: {
    gd: function gd() {
      // console.log(this.$refs.con.offsetHeight);
      $(".gdCon") && $("html,body").animate({
        scrollTop: $(".gdCon").height()
      }, 300);
    }
  },
  props: {
    color: {
      type: String,
      default: "#ffffff"
    },
    icon: {
      type: Image,
      default: require("@/assets/bottomjt.png")
    }
  }
};
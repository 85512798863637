import fn, { stop, start } from "../js/index";
export default {
  methods: {},
  data: function data() {
    return {
      showMobile: false
    };
  },
  watch: {// showMobile: {
    //   handler(b) {
    //     b ? stop() : start();
    //   },
    // },
  },
  mounted: function mounted() {
    var _this = this;

    fn();
    start();
    this.showMobile = document.body.clientWidth < 1500;
    $(window).on("resize", function () {
      // console.log(document.body.clientWidth);
      _this.showMobile = document.body.clientWidth < 1500;
    });
  },
  unmounted: function unmounted() {
    stop();
  }
};